@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

body {
  overflow: scroll;
  font-family: 'Pixel', monospace;
}

.App {
  text-align: center;
}

.button {
  font-family: 'Pixel', monospace;
  color: white;
  outline: none;
  border: 0;
  background: transparent;
  font-size: 1rem;
  text-decoration: none;
}

.button-menu {
  font-family: 'Pixel', monospace;
  color: white;
  outline: none;
  border: 0;
  background: transparent;
  font-size: 1rem;
}

.button-menu {
  visibility: hidden;
}
@media (max-width: 900px) {
  .button-menu {
    visibility: visible;
  }
}


.item {
  line-height: 33px;
  background-color: #b9c5a1;
  width: 100%;
}

.item-text {
  margin: 30px 80px 0px 80px;
  color: #2b525a;
}

.img1 {
  display: inline-block;
  margin-left: 80px;
  margin-top: 70px;
}

.item-title {
  display: inline-block;
  color: white;
  font-size: 50px;
  font-weight: 700;
}

.item-img {
  height: 510px;
  background-image: url(/123.png);
  background-size: cover;
  position: relative;
}

.dragon1 {
}

.dragon2 {
  position: absolute;
  right: 10px;
  top: -82px;
  margin-right: 50px;
}


.buttonn {
  font-family: 'Pixel', monospace;
  color: white;
  outline: none;
  border: 0;
  background: #52a638;
  font-size: 16.5px;
  height: 34px;
  width: 60px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
