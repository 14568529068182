
@font-face {
  font-family: 'Pixel';
  src: url('FreePixel.ttf') format('truetype');
}

body {
  font-family: 'Pixel';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  text-align: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top;
  background-color: black;
}

@media (max-width: 900px) {
  .button {
    display: none;
  }
  .buttonn {
    display: none;
  }
  .button-menu {
    visibility: visible;
    font-family: 'Pixel', monospace;
    color: white;
    outline: none;
    border: 0;
    background: transparent;
    font-size: 1rem;
    text-decoration: none;
    width: 50px;
    margin: 1px 1px 1px 1px;
  }
}

ul {
  position: relative;
}


.header {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 5%;
  margin-right: 5%;
}

.main-header {
  margin-top: 100px;
  font-size: 21px;
  color: grey;
}

#myVideo {
  opacity : 0.6;
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.content {
  position: fixed;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
}
button.upgrade2 {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'Pixel';
  margin-bottom: 20px;
  min-width: 400px;
  padding: 12px 12px;
  color: white;
  background: black;
  margin-top: 20px;
  box-shadow: none;
}

button.upgrade2:hover {
  background: black;
  color: white;
}

button.upgrade {
  font-size: 24px;
  padding-left: 100px;
  width: 400px;
  font-family: 'Pixel';
  padding: 12px 20px;
  border-radius: 5px;
  background: black;
  color: white;
  border: 0;
}

button.upgrade:hover {
  background: grey;
  color: white;
}

button:not(.button):not(.buttonn):not(.button-menu) {
  margin-left: auto;
  margin-right: auto;
}

.footer {
  background: #b9c5a1;
  height:540px;   
  text-align:center;
  color:#2b525a;
  min-width: 100%;
  padding-top: -28px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.textcols {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
  line-height: 15px;
}
.textcols-item {
	display: flex;
	flex-direction: column;
	flex-basis: 100%;
	flex: 1;
  line-height: 15px;
}
.textcols-item + .textcols-item {
	margin-left: 4%;
}